import axios from '../config/Axios';

export const url = '/api/logcampaign';

export const metricsVisutalizationVideo = ({
  token,
  queryFirstInitialDate,
  queryFirstEndInitalDate,
  querySecondInitialDate,
  querySecondEndDate,
  userid,
  idCampaing,
  idAdverising,
  idCompany,
  type,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/metrics`, {
      headers: {
        AuthorizationApp: token
      },
      params: {
        queryFirstInitialDate,
        queryFirstEndInitalDate,
        querySecondInitialDate,
        querySecondEndDate,
        userid,
        idCampaing,
        idAdverising,
        idCompany,
        type,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const totalMetricsLogCampaign = ({
  token,
  queryFirstInitialDate,
  queryFirstEndInitalDate,
  querySecondInitialDate,
  querySecondEndDate,
  userid,
  idCampaing,
  idAdverising,
  idCompany,
  type,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/total`, {
      headers: {
        AuthorizationApp: token
      },
      params: {
        queryFirstInitialDate,
        queryFirstEndInitalDate,
        querySecondInitialDate,
        querySecondEndDate,
        userid,
        idCampaing,
        idAdverising,
        idCompany,
        type,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const infoUserLogCampaign = ({
  token, date1, date2, userid
}) => new Promise((resolve, reject) => {
  if (token && date1 && date2 && userid) {
    axios.post(`${url}/userid/sessions`, {
      date1, date2, userid
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros requeridos'
    })
  }
});

export const infoAdvertisingLogCampaign = ({
  token, date1, date2, idAdverising
}) => new Promise((resolve, reject) => {
  if (token && date1 && date2 && idAdverising) {
    axios.post(`${url}/advertising/sessions`, {
      date1, date2, idAdverising
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros requeridos'
    })
  }
});

export const infoCompanyLogCampaign = ({
  token, date1, date2, idCompany
}) => new Promise((resolve, reject) => {
  if (token && date1 && date2 && idCompany) {
    axios.post(`${url}/company/sessions`, {
      date1, date2, idCompany
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros requeridos'
    })
  }
});
