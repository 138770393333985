import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import { Navigate } from "react-router-dom/dist";
import { addUser, deletetoken } from "../actions/auth.actions";
import { connect } from "react-redux";

// pages
import Users from "./pages/Users/Users";
import Tablets from "./pages/Tablets/Tablets";
import Vehicles from "./pages/Vehicle/Vehicles";
import Companies from "./pages/Companies/Companies";
import Advertising from "./pages/Advertising/Advertising";
import Refers from "./pages/Refers/Refers";
import Campaign from "./pages/Campaign/Campaign";
import Error404 from "./error-pages/Error404";
import Tabletvehicle from "./pages/tabletVehicle/tabletvehicle";
import QrPage from "./pages/QrPage/QrPage";
import Plans from "./pages/Plans/Plans";
import OneCompany from "./pages/Companies/OneCompany";
import OneUser from "./pages/Users/OneUser";
import Register from "./user-pages/Register";
import ContactCompany from "./pages/Companies/ContactCompany";
import RequestInfoUser from "./pages/Users/RequestInfoUser";
import FormRequestInfoUser from "./pages/Users/FormRequestInfoUser";
import ForgotPassword from "./user-pages/ForgotPassword";
import ResetPassword from "./user-pages/ResetPassword";
import OneAdvertising from "./pages/Advertising/OneAdvertising";
const Home = lazy(() => import("./pages/Home/Home"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Login = lazy(() => import("./user-pages/Login"));

const AppRoutes = (props) => {
  const { token, userRole } = props;
  console.log('dentro de app routes');
  return (
    <Suspense fallback={<Spinner />}>
      {token ? (
        <>
          {
            userRole === "admin" ? (
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/metrics/:id" element={<OneUser />} />
                <Route path="/users/request/:id" element={<RequestInfoUser />} />
                <Route path="/advertising" element={<Advertising />} />
                <Route path="/advertising/campaign/:advertisingId" element={<Campaign />} />
                <Route path="/advertising/metrics/:id" element={<OneAdvertising />} />
                <Route path="/tablets" element={<Tablets />} />
                <Route path="/plans" element={<Plans />} />
                <Route path="/vehicles" element={<Vehicles />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/metrics/:id" element={<OneCompany />} />
                <Route path="/companies/contacts/:id" element={<ContactCompany />} />
                <Route path="/referrals" element={<Refers />} />
                <Route path="/tabletpervehicles" element={<Tabletvehicle />} />
                <Route path="/campaign/qr/:id" element={<QrPage />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />
                <Route path="/campaign/qr/:id" element={<QrPage />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            )
          }
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:idtoken" element={<ResetPassword /> } />
          <Route path="/registro-conductores" element={<Register />} />
          <Route path="/solicitud-conductores" element={<FormRequestInfoUser />} />
          <Route path="/campaign/qr/:id" element={<QrPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Suspense>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addUserApp: (user) => dispatch(addUser(user)),
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
